#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
.main-view ._logo{
  height: 40px;
}
.main-view ._header{
  background: #fff;
  padding-left: 10px;
}


.dark-drawer .ant-drawer-header{
  background: transparent;
  border-bottom-color:rgba(255, 255, 255, 0.25) ;
}
.dark-drawer svg{
  color:rgba(255, 255, 255, 0.8);
  fill:rgba(255, 255, 255, 0.8);
}
.dark-drawer .ant-drawer-title{
  color:rgba(255, 255, 255, 0.8)
}
.dark-drawer .ant-drawer-body{
  background: transparent;
  color:rgba(255, 255, 255, 0.8)
}
.dark-drawer .ant-drawer-content{
  background: #001529;
}
