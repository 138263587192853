body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root,.main_container{
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
display: flex;
}

/*拉伸布局  横纵填满 并自适应父容器*/
.xx-stretch{
  position: relative;
  width: 100%;
  height: 100%;
}
/*Hbox（水平分列布局）*/
.xx-hbox{
  display: flex;
  align-items:stretch;
  align-content:stretch;
}
.xx-hbox>*{flex-grow:1;}

/*vbox（垂直分行布局）*/
.xx-vbox{
  flex-direction:column;
  display: flex;
}
.xx-vbox>*{
  width: 100%;
  flex-grow:1;
}
/*Hbox vbox 移除自动适应*/
.xx-no-flex,.xx-hbox>[xx-no-flex="y"],.xx-vbox>[xx-no-flex="y"]{
  flex-grow:0;
}
.xx-link{
  cursor: pointer;
  color: #1890ff;
}

.xx-avatar-{}
.xx-avatar-0 .ant-avatar-circle{
  background: #ec778c;
}
.xx-avatar-1 .ant-avatar-circle{
  background: #1890ff;
}