.xx-auth-panel ._nodelist{
    background: rgba(231, 229, 229, 0.1);
    border: solid 1px #e1e1e1;
    border-radius: 3px;
    overflow-y: scroll;
    height:50%
}

.xx-auth-panel .ant-spin-container{
    height: 100%;
    width: 100%;
}