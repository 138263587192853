.xx_employee_editor ._col{
padding: 3px;
}
.xx_employee_editor ._col_cnt{
   background: #cccccc20;
   border:solid #cccccc45 1px ;
   border-radius: 3px;
   padding: 8px;
}
._org_info{
   display: flex;
   justify-items: center;
}
.xx_drawer_form .xx_employee_editor{
   height: 100%;
   position: relative;
}

.xx_drawer_form .xx_employee_editor ._buttons{
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
}
